<template>
  <div class="home">
    <stamboom></stamboom>
  </div>
</template>

<script>
// @ is an alias to /src
import Stamboom from '@/components/Stamboom.vue';

export default {
  name: 'Home',
  components: {
    Stamboom,
  },
};
</script>
