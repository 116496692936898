import Vue from 'vue';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.prototype.axios = axios;
Vue.config.productionTip = false;
Vue.use(require('d3'));
Vue.use(require('d3-octree'));
Vue.use(require('d3-force-3d'));

Vue.component('multiselect', Multiselect);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
