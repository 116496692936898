<template>
  <div>
    <div id="list-people-modal" class="is-active modal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{node.name}}</p>
      <button class="delete" aria-label="close" @click="closeModal()"></button>
    </header>
    <section class="modal-card-body" style="min-height: 500px;">
      <!-- Content ... -->
      <p>Geslacht: {{(node.gender === 'M' ? 'Man' : 'Vrouw')}}</p>
      <p v-if="node.dob !== ''">Geboren op: {{node.dob}}</p>
      <p v-if="node.pob !== ''">Geboren te: {{node.pob}}</p>
      <p v-if="node.dod !== ''">Overleden op: {{node.dod}}</p>
      <p v-if="node.pod !== ''">Overleden te: {{node.pod}}</p>
      {{d3Data.links.map((link) => {
        if (link.source.id === node.id) {
          return `${link.targetType}  ${link.target.name}`;
        }
      }).join(', ')}}
    </section>
  </div>
</div>
  </div>
</template>
<script>

export default {
  props: ['d3Data', 'node', 'closeModal'],
  data() {
    return {
      person1: undefined,
      person2: undefined,
    };
  },
  methods: {
    getTypeLabel(targetType, gender) {
      let labelName;
      switch (targetType) {
        case 'CHIL':
          if (gender === 'M') {
            labelName = 'zoon';
          }
          labelName = 'dochter';
          break;
        case 'HUSB':
          labelName = 'man';
          break;
        case 'WIFE':
          labelName = 'vrouw';
          break;
        default:
          labelName = '?';
      }
      return labelName;
    },
    listPeople() {
      return this.d3Data.map((d) => d.name);
    },
  },
};

</script>
