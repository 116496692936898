<template>
<div>
    <div id="list-people-modal" class="is-active modal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Zoek persoon</p>
      <button class="delete" aria-label="close" @click="closeModal()"></button>
    </header>
    <section class="modal-card-body" style="min-height: 500px;">
      <!-- Content ... -->

      <label class="label">Persoon</label>
      <multiselect v-model="person" track-by="name" :select-label="''" label="name" placeholder="Kies persoon" :options="d3Data.nodes"></multiselect>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-link" @click="searchPerson(person)">Zoek</button>
      <button class="button is-ghost" @click="closeModal()">Annuleer</button>
    </footer>
  </div>
</div>
  </div>
</template>
<script>
export default {
  props: ['d3Data', 'searchPerson', 'closeModal'],
  data() {
    return {
      person: undefined,
    };
  },
};
</script>
