import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    searchPersonModal: false,
    searchLinkBetweenPeopleModal: false,
    gedcomData: '',
    graphSettings: {
      glow: false,
      threeD: true,
      pulse: false,
      rotate: false,
    },
  },
  mutations: {
    setGedcomData(state, gedcomData) {
      state.gedcomData = gedcomData;
    },
    setGraphSettings(state, graphSettings) {
      state.graphSettings = graphSettings;
    },
    setSearchPersonModal(state, searchPersonModal) {
      state.searchPersonModal = searchPersonModal;
    },
    setSearchLinkBetweenPeopleModal(state, searchLinkBetweenPeopleModal) {
      state.searchLinkBetweenPeopleModal = searchLinkBetweenPeopleModal;
    },
  },
  actions: {
    fetchGedcomData({ commit }) {
      axios({
        url: 'https://01lmovcdia.execute-api.eu-west-1.amazonaws.com/main/gedcom',
        method: 'GET',
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'dbcTmJ5GJP2CxCq71J3bt2DPE80rgdJK4C92pc2q',
        },
      }).then(async (response) => {
        commit('setGedcomData', response.data);
      });
    },
    toggleGlow({ commit }) {
      commit('setGraphSettings', { ...this.state.graphSettings, glow: !this.state.graphSettings.glow });
    },
    toggle3D({ commit }) {
      commit('setGraphSettings', { ...this.state.graphSettings, threeD: !this.state.graphSettings.threeD });
    },
    togglePulse({ commit }) {
      commit('setGraphSettings', { ...this.state.graphSettings, pulse: !this.state.graphSettings.pulse });
    },
    toggleRotate({ commit }) {
      commit('setGraphSettings', { ...this.state.graphSettings, rotate: !this.state.graphSettings.rotate });
    },

  },
  modules: {
  },
});
