<template>
  <div>
    <link rel="stylesheet" href="@sweetalert2/theme-dark/dark.css" />
    <div id="graph"></div>
      <div style="position: absolute; top: 5px; right: 5px;">
      <button id="animationToggle" v-if="false" style="margin: 8px; height: 25px; width: 150px;">
        Pause Animation
      </button>
      <button id="rotationToggle" v-if="false" style="margin: 8px; height: 25px; width: 150px;">
        Pause Rotation
      </button>
      <div>
        <list-people v-if="searchLinkBetweenPeopleModal" :searchLinkBetweenPeople="(person1, person2)=>{showRelation(person1, person2)}" :d3Data="d3Data" :closeModal="()=>{searchLinkBetweenPeopleModal = false}"/>
        <search-person v-if="searchPersonModal" :searchPerson="(person)=>{focusOnNode(person)}" :d3Data="d3Data" :closeModal="()=>{searchPersonModal = false}"/>
        <person-info v-if="personInfoModal" :d3Data="d3Data" :node="selectedNode" :closeModal="()=>{personInfoModal = false}"  />
      </div>
    </div>
  </div>
</template>

<script>

import ForceGraph3D from '3d-force-graph';
import ForceGraph from 'force-graph';
import { d3ize, parse } from 'gedcom-d3';
import SpriteText from 'three-spritetext';
import Swal from 'sweetalert2';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import d3 from 'd3';
import ListPeople from './ListPeople.vue';
import SearchPerson from './SearchPerson.vue';
import PersonInfo from './PersonInfo.vue';
// import * as d3octree from 'd3-octree';
// import * as d3force3d from 'd3-force-3d';

export default {
  components: { ListPeople, SearchPerson, PersonInfo },
  computed: {
    gedcomData() {
      return this.$store.state.gedcomData;
    },
    graphSettings() {
      return this.$store.state.graphSettings;
    },
    searchPersonModal: {
      get() {
        return this.$store.state.searchPersonModal;
      },
      set(value) {
        this.$store.commit('setSearchPersonModal', value);
      },
    },
    searchLinkBetweenPeopleModal: {
      get() {
        return this.$store.state.searchLinkBetweenPeopleModal;
      },
      set(value) {
        this.$store.commit('setSearchLinkBetweenPeopleModal', value);
      },
    },
  },
  data() {
    return {
      d3Data: undefined,
      personInfoModal: false,
      selectedNode: undefined,
      highlightNodes: new Set(),
      highlightLinks: new Set(),
      pulse: undefined,
      rotate: undefined,
      ForceGraph3D,
      ForceGraph,
      SpriteText,
      Swal,
      d3ize,
      parse,
      UnrealBloomPass,
      d3,
      Graph: undefined,
    };
  },
  watch: {
    gedcomData() {
      this.render3DGraph();
    },
    graphSettings(newVal) {
      // this.render3DGraph();
      // console.log(this.Graph.postProcessingComposer().passes);
      if (newVal.glow) {
        if (!this.Graph.postProcessingComposer().passes[1]) {
          const bloomPass = new UnrealBloomPass();
          bloomPass.strength = 2;
          bloomPass.radius = 1;
          bloomPass.threshold = 0.1;
          this.Graph.postProcessingComposer().addPass(bloomPass);
        }
      } else {
        this.Graph.postProcessingComposer().removePass(this.Graph.postProcessingComposer().passes[1]);
      }
      if (newVal.pulse && !this.pulse) {
        this.pulse = setInterval(() => {
          [...Array(10).keys()].forEach(() => {
            const link = this.d3Data.links[Math.floor(Math.random() * this.d3Data.links.length)];
            this.Graph.emitParticle(link);
          });
        }, 1000);
      } else {
        clearInterval(this.pulse);
        this.pulse = undefined;
      }
      let angle = 0;
      const distance = 1400;
      if (newVal.rotate && !this.rotate) {
        this.rotate = setInterval(() => {
          this.Graph.cameraPosition({
            x: distance * Math.sin(angle),
            z: distance * Math.cos(angle),
          })
            .cameraPosition({ z: distance });
          angle += Math.PI / 1600;
        }, 10);
      }
      if (!newVal.rotate && this.rotate) {
        clearInterval(this.rotate);
        this.rotate = undefined;
      }
    },
  },
  methods: {
    getFamily(family) {
      console.log(family);
    },
    // search for a person in matches
    search(id, id2) {
      const search = this.matches.find((match) => match.a === id && match.b === id2);
      // const search2 = this.matches.find((match) => match.b === id && match.a === id2);

      if (search) {
        return true;
      }

      return false;
    },
    focusOnNode(node) {
      const distance = 200;
      const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);
      this.highlightLinks.clear();
      this.highlightNodes.clear();
      this.highlightNodes.add(node);
      this.updateHighlight();
      this.Graph.cameraPosition(
        { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
        node, // lookAt ({ x, y, z })
        3000, // ms transition duration
      );
      this.searchPersonModal = false;
    },
    findBreadthLink(d3Links, person1, person2) {
      const addNode = (graph, node) => {
        graph.set(node, { in: new Set(), out: new Set() });
      };

      const connectNodes = (graph, source, target) => {
        graph.get(source).out.add(target);
        graph.get(target).in.add(source);
      };

      const buildGraphFromEdges = (edges) => edges.reduce(
        (graph, { source, target }) => {
          if (!graph.has(source)) {
            addNode(graph, source);
          }

          if (!graph.has(target)) {
            addNode(graph, target);
          }

          connectNodes(graph, source, target);

          return graph;
        },
        new Map(),
      );

      const buildPath = (target, path) => {
        const result = [];

        while (path.has(target)) {
          const source = path.get(target);
          result.push({ source, target });
          target = source;
        }
        return result.reverse();
      };

      const findPath = (source, target, graph) => {
        if (!graph.has(source)) {
          throw new Error('Unknown source.');
        }

        if (!graph.has(target)) {
          throw new Error('Unknown target.');
        }

        const queue = [source];
        const visited = new Set();
        const path = new Map();

        while (queue.length > 0) {
          const start = queue.shift();

          if (start === target) {
            return buildPath(start, path);
          }

          // eslint-disable-next-line no-restricted-syntax
          for (const next of graph.get(start).out) {
            if (visited.has(next)) {
              // eslint-disable-next-line no-continue
              continue;
            }

            if (!queue.includes(next)) {
              path.set(next, start);
              queue.push(next);
            }
          }

          visited.add(start);
        }

        return null;
      };

      //  A --* B
      //      / | \
      //     *  |  *
      //    C   |   D --* E
      //     \  |  /     *
      //      * * *     /
      //        F------/

      const graph = buildGraphFromEdges(d3Links);
      return findPath(person1, person2, graph); // []
      // console.log('A -> F', findPath('A', 'F', graph)); // [{source: 'A', target: 'B'}, {source: 'B', target: 'F'}]
      // console.log('A -> E', findPath('A', 'E', graph)); // [{source: 'A', target: 'B'}, {source: 'B', target: 'D'}, {source: 'D', target: 'E'}]
      // console.log('E -> A', findPath('E', 'A', graph)); // null
    },
    getNode(id) {
      return this.d3Data.nodes.find((node) => node.id === id);
    },
    getLink(id, id2) {
      if (this.d3Data.links.find((link) => link.source.id === id && link.target.id === id2)) {
        return this.d3Data.links.find((link) => link.source.id === id && link.target.id === id2);
      }
      return this.d3Data.links.find((link) => link.source.id === id2 && link.target.id === id);
    },
    getName(node) {
      return `${node.firstName} ${node.surname}`;
    },
    showRelation(person1Node, person2Node) {
      // recursively find links between a 2 source node id's through target node id's
      // and return the links
      const person2 = person2Node.id;
      const person1 = person1Node.id;
      const fullLinks = [...this.d3Data.links.map((link) => ({ source: link.source.id, target: link.target.id })), ...this.d3Data.links.map((link) => ({ source: link.target.id, target: link.source.id }))];
      // try from person one
      // d3Data.links.filter((link) => link.target === personOneId);
      const match = this.findBreadthLink(
        fullLinks,
        person1, person2,
      );
      this.highlightNodes.clear();
      this.highlightLinks.clear();
      this.searchLinkBetweenPeopleModal = false;
      // eslint-disable-next-line array-callback-return
      match.map((link) => {
        // get person node
        this.highlightLinks.add(this.getLink(link.target, link.source));
        this.highlightNodes.add(this.getNode(link.source));
        this.highlightNodes.add(this.getNode(link.target));
      });
      this.updateHighlight();
    },
    fetchGedcomData() {
      this.axios({
        url: 'https://01lmovcdia.execute-api.eu-west-1.amazonaws.com/main/gedcom',
        method: 'GET',
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'dbcTmJ5GJP2CxCq71J3bt2DPE80rgdJK4C92pc2q',
        },
      }).then(async (response) => {
        this.$store.commit('setGedcomData', response.data);
      });
    },
    updateHighlight() {
      // trigger update of highlighted objects in scene
      this.Graph
        .nodeColor(this.Graph.nodeColor())
        .linkWidth(this.Graph.linkWidth())
        .linkDirectionalParticles(this.Graph.linkDirectionalParticles());
    },
    render3DGraph() {
      // run fetchGedcomData from store;

      this.d3Data = this.d3ize(this.parse(this.gedcomData));

      // this.showRelation(this.d3ize(this.parse(this.gedcomData)));

      // let Graph;
      if (this.graphSettings.threeD) {
        this.Graph = this.ForceGraph3D({ controlType: 'orbit' })(document.getElementById('graph'))
          .graphData(this.d3Data)
          .nodeAutoColorBy('group')

          .nodeColor((node) => (this.highlightNodes.has(node) ? 'rgba(255,160,0,0.8)' : 'rgba(0,255,255,0.6)'))
          .linkWidth((link) => (this.highlightLinks.has(link) ? 4 : 1))
          .linkDirectionalParticles((link) => (this.highlightLinks.has(link) ? 4 : 0))
        // .linkDirectionalArrowLength(3.5)
        // .linkDirectionalArrowRelPos(1)
        // .linkCurvature(0.25)

          .linkDirectionalParticleColor(() => 'white')
          .linkDirectionalParticleWidth(4)
          .onNodeClick((node) => {
            this.personInfoModal = true;
            this.selectedNode = node;
            // const tableContent = Object.keys(node).map((key) => `<tr><td>${key}</td><td>${node[key]}</td></tr>`).join('');
            // this.Swal.fire({
            //   title: `${node.name}`,
            //   html:
            //   `<table>${tableContent}</table>`,
            //   showCloseButton: true,
            //   showConfirmButton: false,
            // });
          })

          .nodeThreeObject((node) => {
            const sprite = new this.SpriteText(`${node.name}`);
            sprite.material.depthWrite = false; // make sprite background transparent
            sprite.color = node.color;
            sprite.textHeight = 5;
            sprite.fontSize = 50;
            return sprite;
          });
      } else {
        this.Graph = this.ForceGraph({ controlType: 'orbit' })(document.getElementById('graph'))
          .graphData(this.d3Data)
          .backgroundColor('#000011')
          .nodeAutoColorBy('group')
          .linkColor(() => 'grey')
          .nodeCanvasObject((node, ctx, globalScale) => {
            const label = node.name;
            const fontSize = 12 / globalScale;
            ctx.font = `${fontSize}px Sans-Serif`;
            const textWidth = ctx.measureText(label).width;
            const bckgDimensions = [textWidth, fontSize]
              .map((n) => n + fontSize * 0.2); // some padding

            ctx.fillStyle = 'rgba(255, 255, 255, 0.0)';
            ctx
              .fillRect(
                node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions,
              );

            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = node.color;
            ctx.fillText(label, node.x, node.y);

            node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
          })
          .nodePointerAreaPaint((node, color, ctx) => {
            ctx.fillStyle = color;
            const bckgDimensions = node.__bckgDimensions;
            bckgDimensions && ctx
              .fillRect(
                node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions,
              );
          });
      }
      // Deactivate existing forces

      // console.log(this.d3Data);

      // document.getElementById('rotationToggle').addEventListener('click', (event) => {
      //   isRotationActive = !isRotationActive;
      //   // eslint-disable-next-line no-param-reassign
      //   event.target.innerHTML = `${(isRotationActive ? 'Pause' : 'Resume')} Rotation`;
      // });

      // let isAnimationActive = true;
      // document.getElementById('animationToggle').addEventListener('click', (event) => {
      //   // eslint-disable-next-line no-unused-expressions
      //   isAnimationActive ? Graph.pauseAnimation() : Graph.resumeAnimation();

      //   isAnimationActive = !isAnimationActive;
      //   // eslint-disable-next-line no-param-reassign
      //   event.target.innerHTML = `${(isAnimationActive ? 'Pause' : 'Resume')} Animation`;
      // });
    },
  },
  mounted() {
    this.fetchGedcomData();
    this.render3DGraph();
  },
};

</script>
