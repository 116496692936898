<template>
  <div>
    <div class="graph-navigation" v-if="!toggleMenu" >
      <button @click="toggleMenu = !toggleMenu"
      class="button is-small is-light is-link is-outlined">
      <span class="icon is-small">
        <menu-icon></menu-icon>
      </span>
    </button>
    </div>
    <div v-else class="graph-navigation">
    <nav class="panel has-background-white">
  <div class="panel-heading is-flex is-justify-content-space-between is-align-items-center">
    <button @click="toggleMenu = !toggleMenu" class="button is-small is-light is-link is-outlined">
      <span class="icon is-small">
        <minus-icon></minus-icon>
      </span>
    </button>
    <p>Stamboom</p>
  </div>
  <div class="panel-block">
    <div class="field">
      <input id="glow" type="checkbox" class="switch is-info" v-model="glow">
      <label for="glow">Glow</label>
    </div>
  </div>
  <div class="panel-block">
    <div class="field">
      <input id="threed" type="checkbox" class="switch is-info" v-model="threeD">
      <label for="threed">3D</label>
    </div>
  </div>
  <div class="panel-block">
    <div class="field">
      <input id="pulse" type="checkbox" class="switch is-info" v-model="pulse">
      <label for="pulse">Pulse</label>
    </div>
  </div>
  <div class="panel-block">
    <div class="field">
      <input id="rotate" type="checkbox" class="switch is-info" v-model="rotate">
      <label for="rotate">Rotate</label>
    </div>
  </div>
  <div class="panel-block">
    <button class="button is-link is-fullwidth" @click="()=>{searchPersonModal = true; searchLinkBetweenPeopleModal = false}">Zoek persoon</button>
  </div>
  <div class="panel-block">
    <button class="button is-link is-fullwidth" @click="searchLinkBetweenPeopleModal = true; searchPersonModal = false">Zoek link</button>
  </div>
  <div class="panel-block">
    <div class="file is-dark">
      <label class="file-label">
        <input class="file-input"
        accept=".ged"
        @change="uploadFile($event.target.name, $event.target.files)" type="file" name="resume">
        <span class="file-cta ">
          <span class="file-icon">
            <i class="fas fa-upload"></i>
          </span>
          <span class="file-label">
            Kies een bestand...
          </span>
        </span>
      </label>
    </div>
  </div>
</nav>
  </div>
  </div>
</template>

<style lang="scss">
  .graph-navigation{
    position: absolute;
    z-index: 39;
  }
</style>

<script>
import { MinusIcon, MenuIcon } from 'vue-feather-icons';

export default {
  components: {
    MinusIcon,
    MenuIcon,
  },
  data() {
    return {
      toggleMenu: false,
    };
  },
  computed: {
    threeD: {
      get() {
        return this.$store.state.graphSettings.threeD;
      },
      set() {
        this.$store.dispatch('toggle3D');
      },
    },
    pulse: {
      get() {
        return this.$store.state.graphSettings.pulse;
      },
      set() {
        this.$store.dispatch('togglePulse');
      },
    },
    rotate: {
      get() {
        return this.$store.state.graphSettings.rotate;
      },
      set() {
        this.$store.dispatch('toggleRotate');
      },
    },
    glow: {
      get() {
        return this.$store.state.graphSettings.glow;
      },
      set() {
        this.$store.dispatch('toggleGlow');
      },
    },
    searchPersonModal: {
      get() {
        return this.$store.state.searchPersonModal;
      },
      set(value) {
        this.$store.commit('setSearchPersonModal', value);
      },
    },
    searchLinkBetweenPeopleModal: {
      get() {
        return this.$store.state.searchLinkBetweenPeopleModal;
      },
      set(value) {
        this.$store.commit('setSearchLinkBetweenPeopleModal', value);
      },
    },
  },
  methods: {
    uploadFile(name, files) {
      const formData = new FormData();
      formData.append('file', files[0]);
      this.axios.post('https://01lmovcdia.execute-api.eu-west-1.amazonaws.com/main/gedcom',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-api-key': 'dbcTmJ5GJP2CxCq71J3bt2DPE80rgdJK4C92pc2q',
          },
        }).then(() => {
        this.$store.dispatch('fetchGedcomData');
      });
    },
  },
};

</script>
