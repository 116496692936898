<template>
  <div>
    <div id="list-people-modal" class="is-active modal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Zoek link</p>
      <button class="delete" aria-label="close" @click="closeModal()"></button>
    </header>
    <section class="modal-card-body" style="min-height: 500px;">
      <!-- Content ... -->

      <label class="label">Persoon 1</label>
      <multiselect v-model="person1" track-by="name" label="name" :select-label="''" placeholder="Kies persoon 1" :options="d3Data.nodes"></multiselect>
      <label class="label">Persoon 2</label>
      <multiselect v-model="person2" track-by="name" label="name" :select-label="''" placeholder="Kies persoon 2" :options="d3Data.nodes"></multiselect>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-link" @click="searchLinkBetweenPeople(person1, person2)">Zoek</button>
      <button class="button is-ghost" @click="closeModal()">Annuleer</button>
    </footer>
  </div>
</div>
  </div>
</template>
<script>

export default {
  props: ['d3Data', 'searchLinkBetweenPeople', 'closeModal'],
  data() {
    return {
      person1: undefined,
      person2: undefined,
    };
  },
  methods: {
    listPeople() {
      return this.d3Data.map((d) => d.name);
    },
  },
};

</script>
<style lang="scss">

  @import '../assets/css/multiselect.css';
  // #list-people-modal{
  //   position: absolute;
  //   z-index: 9999;
  // }
</style>
